import React from "react";
import Header from "components/shared/Header";
import Layout from "components/shared/Layout";
import Footer from "components/shared/FooterEN";
import Nav from "components/shared/NavEN";
import Meta from "components/shared/Meta";

import {entryContent} from "./index.module.less";

export default () => {
  return (
    <>
      <Meta url="/Terms adn conditions/" />
      <Nav />
      <Header>
        <h1>General Terms and Conditions</h1>
      </Header>
      <Layout>
        <div className={entryContent}>
            <ol>
                <li>
                <bold>GENERAL TERMS AND CONDITIONS</bold>
                <br />1.1 1.1Before using the website, products or services owned or operated directly or indirectly by LetsGrow.com.cn. (‘ ‘LetsGrow.com.cn’), please take note of and agree to our General Terms and Conditions of Sale and Delivery.
                <br />1.2 1.2These General Terms and Conditions are part of offers and agreements between LetsGrow.com.cn and its customers. In this context, ‘Customers’ means the person to whom offers are addressed or with whom LetsGrow.com.cn has concluded an agreement, such as partners and dealers, as well as the end users of LetsGrow.com.cn  and MyLetsGrow, LetsGrow.com.cn’s web portal. All the provisions of these Terms and Conditions are in force between the parties, except where the the parties expressly deviated from them in writing.
                </li>
                <li>
                <bold>Offers and agreements</bold>
                <br />2.1 All of LetsGrow.com.cn’s offers are
                subject to confirmation without obligation. An agreement is only
                deemed to be concluded if and insofar as LetsGrow.com accepts an
                order from Client in writing or if LetsGrow.com.cn commences with
                the execution of the order.
                </li>
                <li>
                <bold>Customers rights to the website portal</bold>
                <br />3.1 The use of the
                software via LetsGrow.com.cn’s, MyLetsGrow web portal is a socalled
                SaaS solution, i.e. software as a service (the “Webportal”). This
                means that Customer itself does not have access to the software;
                instead it is offered as an online service. <br />LetsGrow.com.cn
                is never obliged to provide a physical carrier containing software,
                like the object code for instance, in the context of the SaaS solution
                and may use third party publisher to commercialize its SaaS
                solution (the “Publisher”).
                <br />3.2 Customer must do whatever is
                necessary to ensure that access to the web application is gained
                on time and correctly.
                <br />3.3 An API (application programming
                interface) may be required for use of the web application.
                Sharing the key provided for this with third parties is not
                permitted. Passwords that are either provided or created are
                unique and may also not be shared with third parties.
                <br />3.4
                The Customer can create accounts for its own users in MyLetsGrow, by
                the Publisheror ask LetsGrow.com.cn to do so for it. Only one
                person may log in to each sub-account created for a user.
                Allowing third parties to use the SaaS solution provided by
                LetsGrow.com.cn is not permitted.
                <br />3.5 The total number of
                subaccounts may be limited in accordance with the order confirmation.
                <br />3.6 LetsGrow.com.cn will create or cause to create a sub-domain in the
                internet domain used for the web application, and will ensure
                that the Customer can access the web application through this
                sub-domain.
                <br />3.7 The Customer takes full responsibilities for
                content generated from its use of the Webportal (including but
                not limited to messages, comments, names etc). The Customer
                guarantees that the above do not violate any law, regulation,
                policy, public order and moral. Otherwise, the Customer needs to
                take measures such as delete illegal content or alert
                LetsGrow.com.cn and/or the Publisher.
                <br />3.8. The Customer shall not use any plug-in without prior approval from LetsGrow.com.cn .
                <br />3.9 Through the use the Webportal, the Customer shall not participate in
                or assist illegal actions including but not limited to:
                <br />i.
                Any action that is against basic principles of the PRC
                Constitution;<br />ii. Any action that is endangering national
                security, leaking state secrets, subverting state power,
                undermining national unity;<br />iii. Any action that is harmful to
                national honor and interests;<br />iv. Any action that incites ethnic
                hatred, ethnic discrimination, and is undermining ethnic
                solidarity;<br />v. Any action that violates the state religion
                policies or propagates cult and feudal superstition;<br />vi. Any
                action that spreads rumors, disturbs social order, or undermines
                social stability;<br />vii. Any action that spreads obscenity,
                pornography, gambling, violence, homicide, terror or instigate
                crimes;<br />viii. Any action that insults or slanders others,
                infringes the legitimate rights and interests of others;<br />ix.
                Any action that infringes the legal rights of intellectual property or
                commercial secrets;<br />x. Any action that maliciously conceals
                the truth in order to mislead or deceive;<br />xi. Any action that
                releases, transfers, disseminates advertising information and spam;<br />xii.
                Other action that is forbidden by laws or regulations.
                <br />3.10. The
                Customer shall not conduct or assist following actions:<br />i.
                Delete, conceal, or change any statement of patent, copyright,
                trademark or other ownership;<br />ii. Disturb or intend to
                disturb normal operation of Webportal or any part or component of
                the Webportal, or stipulate or publish tools or methods to conduct the
                above;<br />iii. Avoid or try to avoid any content protection
                system;<br />iv. Use the the Webportal’s URL or technology interface
                in any forms without written consent of LetsGrow.com.cn or the
                Publisher;<br />v. Contact uses directly or send commercial
                advertisement or spam to the LetsGrow.com.cn r’s customers;<br />vi.
                Provide trace facility which allow the Customer to, include but not
                limited to, identify another user of the Webportal visit or
                click;<br />vii. Automatically change the browser setting to direct
                the window to another website;<br />viii. Acquire the access right
                without authorization to the Publisher’s products or services;<br />ix.
                To contain computer viruses, Trojan or other malicious programs that
                might impair interests and information security of any Party;<br />x.
                Set or publish any Webportal’s usage or content that violate relevant
                regulation, public order and morals, social morals;<br />xi.
                publicly express that it has cooperation relationship with the
                Publisher, including but not limited to cross-shareholding,
                commercial relationship or partnership etc, without written
                consent of the Publisher;<br />xii. Other activities or content that
                the Publisher or the Developer considers not appropriate.
                </li>
                <li>
                <bold>Service provision</bold>
                <br />4.1 All LetsGrow.com.cn services are performed
                for Customers on the basis of a best efforts obligation,
                regardless of whether it concerns the SaaS solution, training courses,
                data analyses, advice or monitoring of their use.
                <br />4.2
                LetsGrow.com.cn will make every effort to minimise the downtime of its
                SaaS solution. LetsGrow.com.cn cannot guarantee the exact amount
                of the uptime. LetsGrow.com.cn may change the content, operation
                or scope of the SaaS solution. LetsGrow.com.cn may also
                temporarily decommission the SaaS solution entirely or in part so
                that it can carry out preventive, corrective or adaptive maintenance
                or other kinds of service. LetsGrow.com.cn will make every effort
                to keep this decommissioning to a minimum. LetsGrow.com.cn will
                send the Customer messages in good time to notify them of
                changes, maintenance or decommissioning. LetsGrow.com.cn is not
                obliged to maintain, change or add certain features or
                functionalities in the SaaS solution specifically for a Customer.
                <br />4.3
                LetsGrow.com.cn offers training courses for the Customer’s staff so
                that they can familiarise themselves with options for using the
                data that becomes available through LetsGrow.com.cn’s SaaS solution.
                LetsGrow.com.cn may set requirements for the qualifications that
                the Customer’s staff must have to be eligible for training and
                other support services. The Customer is and remains solely
                responsible for using the SaaS solution correctly, and for deploying
                expert staff who LetsGrow.com.cn has trained in how to use the
                SaaS solution.
                <br />4.4 For support services such as training, data
                analyses, advice and monitoring the use of the SaaS solution,
                Customer is and remains fully responsible for the use of the SaaS
                solution in its own business processes. The SaaS solution only
                offers process support for some of the business processes. The
                Customer’s use of the SaaS solution is entirely at its own
                risk.LetsGrow.com.cn cannot guarantee the correctness,
                completeness or timeliness of the support provided by the SaaS solution,
                nor can it be held liable for damages resulting from decisions taken
                by Customer based on the SaaS solution training, data analyses,
                advice and monitoring.
                </li>
                <li>
                <bold>Use of the information, collect and transfer of data</bold>
                <br />5.1 The data
                and information on the LetsGrow.com.cn server are provided by LetsGrow.com.cn’s
                Customers. Customers specially consent to the collect and transfer
                of data (whether personal identifiable information, critical
                information, business information or other) to LetsGrow.com.cn in
                accordance with applicable regulations. LetsGrow.com.cn may only
                use the data and information for its own internal purposes, e.g.
                problem solving, product development. The data and information
                may only be made available to third-party LetsGrow.com.cn users with
                the explicit prior user’s permission. The user may withdraw this
                permission at any time. LetsGrow.com.cn cannot be held
                responsible and/or liable for the correctness of the data and
                information provided. LetsGrow.com.cn cannot be held liable for
                damages due to confidential information becoming public, regardless
                of the reason. In accordance with the relevant PRC regulation the LetsGrow.com.cn
                shall respect the Customer’s rights in regards of data protection and
                data privacy including without being limited to ght to be informed,
                right to access, correct or erase information, right to oppose
                automated processing, right to lodge a complaint to the relevant
                authorities, and the Customer’s data confidentiality, integrity
                and accessibility.
                <br />5.2. Customer’s data is stored and saved by
                LetsGrow.com.cn with an interval of 5 minutes and is stored for a
                duration of 2 years on LetsGrow.com.cn and/or the Publisher’s
                server. Derived data such as day, night and 24 hours data as well
                as manually entered data, is stored for a duration of 4 years.
                <br />5.3.
                LetsGrow.com.cn ensures a regular back-up of data files on the servers
                but accepts no responsibility for the possible data loss and
                subsequent damages arising from this. The Customer is solely
                responsible for the back-up of its own data.
                <br />5.4 More
                information regarding the Customer’s personal information privacy
                and protection may be obtained at info@letsgrow.com.cn
                </li>
                <li>
                <bold>Supplying hardware</bold>
                <br />6.1 Dimensions and specifications for hardware
                supplied by LetsGrow.com.cn stated in catalogues, quotations,
                drawings, etc are only indicative. Prices are based on delivery
                Ex Works, excluding VAT and other levies and taxes. The delivery
                times stated are non-binding and are based on the circumstances
                applicable at the time that the agreement between LetsGrow.com.cn
                and its suppliers is concluded. If those circumstances change,
                the price and delivery time may change accordingly.
                <br />6.2
                Ownership of the hardware delivered only transfers to Customer once LetsGrow.com.cn
                has been paid the purchase price for the hardware plus any fees
                for additional services. Without prejudice to this retention of title,
                the risk transfers to the Customer at the time of delivery of the
                hardware to the Customer, if it concerns a direct delivery to the
                Customer. 
                <br />6.3 LetsGrow.com.cn only provides a
                guarantee that it can claim from the subsupplier of the hardware in
                question.
                </li>
                <li>
                <bold>Nature of the internet – force majeure – system maintenance</bold>
                <br />7.1
                The internet is not always a reliable communication medium and its use
                is entirely at the Customer’s expense and risk. Disruptions,
                errors and/or delays can occur at any time. LetsGrow.com.cn will
                not be in default in the event of, and is not liable for damages
                caused by, failures in the power supply or failures in
                communication links or equipment, regardless of whether this
                equipment or these connections are managed by LetsGrow.com.cn or
                a third party, or are due to other causes as a result of which
                MyLetsGrow and the associated LetsGrow.com.cn or third-party facilities
                cannot be used or are slowed down. The Customer is always
                responsible for ensuring that all communication connections,
                sensors, computers, power supplies, cameras and other aids are
                properly maintained, and that they are and remain fully
                operational.
                </li>
                <li>
                <bold>Intellectual property rights</bold>
                <br />8.1 The LetsGrow.com.cn website and
                the MyLetsGrow web portal are protected by copyright, and using
                them in violation of copyright law and other applicable laws and
                regulations is not permitted. The intellectual property rights with
                regard to the information offered via the website are vested in
                LetsGrow.com.cn or third parties. Reproducing, editing,
                disclosing to the public or commercially exploiting the
                information on the website and the MyLetsGrow web portal is not
                permitted, unless this is done through a reference to
                LetsGrow.com.cn and only insofar as it is permitted by law. All
                intellectual porperty generated by Customer in relation with use
                or operation of Webportal or LetsGrow.com.cn website belong to LetsGrow.com.cn.
                </li>
                <li>
                <bold>Back-up</bold>
                <br />9.1 LetsGrow.com.cn is responsible for ensuring that data
                files on the servers are backed up regularly. However, it
                disclaims all responsibility for any loss of data suffered by the
                Customer and any resulting damages.
                </li>
                <li>
                <bold>Blocking access to accounts</bold>
                <br />10.1 LetsGrow.com.cn reserves the
                right to block access to accounts or data without prior
                explanation if:<br />• it detects fraudulent use;<br />• the
                Customer’s trial subscription or subscription expires;<br />• the
                e-mail address registered with the account does not exist or does
                not belong to the account’s administrator or user;<br />• the
                Customer is declared bankrupt, has been granted a suspension of payment
                by court order or an administrator or receiver has been appointed over
                its assets;<br />• the payment for a subscription is not made within
                the period stated on the invoice.
                </li>
                <li>
                <bold>Prices</bold>
                <br />11.1LetsGrow.com.cn reserves the right to renew or extend
                subscriptions as well as to adjust the associated rates for other
                products and services.
                <br />11.2The prices for products and services
                will be subject to specific terms and conditions.
                </li>
                <li>
                <bold>Connection and reconnection</bold>
                <br />12.1 LetsGrow.com.cn charges for
                connecting new devices. In principle, a standard rate is used for
                this, but LetsGrow.com.cn is entitled to charge additional costs if the
                connection or link to device in question is not made within the
                estimated time, for instance due to the complexity of the
                connection in question. Costs for connecting sensors
                supplied by third parties will always be charged separately based
                on the rates applicable at the time.
                <br />12.2 If a device or data
                source, including a climate computer or a sensor, has to be reconnected
                to MyLetsGrow due to, for instance, an update, reinstallation, restoring
                of a backup and/or replacing the climate computer, LetsGrow.com.cn will
                charge for this based on the rates applicable at the time. The charges
                for this may not necessarily be lower than for a new connection.
                </li>
                <li>
                <bold>Customised work and modifications</bold>
                <br />13.1 In addition to standard
                subscriptions, customised solutions are also available, such as
                connecting to another software system or changes to the SaaS
                solution at the Customer’s request. LetsGrow.com.cn will give the
                Customer a quotation prior to the customisation or the requested
                adjustment and will only start implementation once it has a
                written order to that effect, for instance by e-mail, from the
                Customer.
                </li>
                <li>
                <bold>Duration and termination of the subscription</bold>
                <br />14.1 If no other
                term has been explicitly agreed, the minimal contract term for a subscription
                (i.e. the user’s licence) to LetsGrow.com.cn/MyLetsGrow is one year.
                After expiration of the original agreed term, the subscription will
                be automatically extended under the same conditions for a period
                of one year each time, unless cancellation takes place with due
                observance of the provisions set out below.
                <br />14.2 The
                Customer is bound by a notice period of one month; i.e., cancellation
                of the subscription must take place at least one month prior to
                the end of the contract period. Notice to terminate the
                subscription must take place in writing or by an email addressed to
                the service desk.
                <br />14.3 If termination takes place prior to the
                expiry of the contract term, the end customer will owe all costs
                for the remaining period of that contract term.
                <br />14.4
                LetsGrow.com.cn reserves the right, but is not obliged, to terminate
                the licence early by giving notice to terminate if, in
                LetsGrow.com.cn’s reasonable opinion, the Customer does not use
                the SaaS solution with the correct level of expertise or in an incorrect
                or irresponsible manner in its business processes or if it
                attributes disappointing cultivation results to LetsGrow.com.cn.
                LetsGrow.com.cn is entitled to invoke this right at any time. If
                LetsGrow.com.cn does not invoke this right, this never
                constitutes an acknowledgement that Customer has used the SaaS
                solution with the correct level of expertise or in a correct or
                responsible manner at any time. In addition, LetsGrow.com.cn is
                entitled to cancel the agreement with immediate effect, without
                prejudice to its rights under the law, in the event of:
                    <ol>

                        <li>
                        the Customer’s bankruptcy, suspension of payments or liquidation;
                        </li>
                        <li>
                        repeated late payment by the Customer of amounts owed by it to LetsGrow.com.cn,
                        including the annual connection charges;
                        </li>
                        <li>
                        the SaaS solution being used in contravention of these General Terms
                        and Conditions.
                        </li>
                    </ol>
                </li>
                <li>
                <bold>Payment</bold>
                <br />15.1 Payment is subject to specififc terms and
                conditions.
                </li>
                <li>
                <bold>Limitations of Liability</bold>
                <br />16.1 LetsGrow.com.cn shall not be liable
                to the Customer for any claim (whether arising in or for
                contract, tort (including negligence), breach of statutory duty, misrepresentation
                or otherwise) under or in connection with these Terms and Conditions
                for:<br />(a) any loss of profit, revenue, anticipated savings,
                business or contract; and<br />(b) any special, indirect or
                consequential loss. LetsGrow.com.cn’s cumulative liability
                towards Customer regardless of the legal basis, such as a
                wrongful act, attributable breach, obligation to cancel or guarantee,
                amounts to a maximum of 10% of the amount paid by Customer to
                LetsGrow.com.cn during one year, up to an absolute maximum of CNY [30,000].
                <br />16.2
                The limitations or exclusions of liability stipulated above do not
                apply if the claimed damage is the result of deliberate intent or
                gross negligence on the part of LetsGrow.com.cn’s managers or
                equivalent subordinates.
                </li>
                <li>
                <bold>Contents of the website</bold>
                <br />17.1 The information provided by
                LetsGrow.com.cn on its website is of a general nature, only
                indicative and subject to change. While LetsGrow.com.cn pays the utmost
                care and attention to the information posted on its website,
                inaccuracies and/or imperfections may occur on the
                LetsGrow.com.cn website.
                <br />17.2 Use of the data and information on
                the website is entirely at the Customer’s risks.
                <br />17.3
                LetsGrow.com.cn accepts no liability for damages resulting from
                inaccuracies or imperfections, or damages arising from or in
                connection with the use, reliance on or distribution of the data
                and information.
                </li>
                <li>
                <bold>Privacy policy</bold>
                <br />18.1 LetsGrow.com.cn processes Customer data in
                accordance with its privacy policy as published most recently on
                its website. The Customer may visit other websites via references
                or hyperlinks on the LetsGrow.com.cn website; the privacy policy
                of the organisation in question applies. LetsGrow.com.cn advises its
                Customers to first familiarise themselves with the privacy policy of
                the organisation before making personal and/or company data
                available.
                </li>
                <li>
                <bold>Amendments to the General Terms and Conditions</bold>
                <br />19.1
                LetsGrow.com.cn reserves the right to amend the General Terms and
                Conditions. It will notify the Customer at least one month prior
                to the coming into force of the changes.
                </li>
                <li>
                <bold>Applicable law and dispute resolution</bold>
                <br />20.1 These General Terms
                and Conditions are governed by the laws of the People’s Republic
                of China (“PRC”) excluding for the purpose of these general terms
                and conditions the special adminsitrative regions of Hong Kong,
                Macau and Taiwan.
                <br />20.2 Any disputes between LetsGrow.com.cn and
                Customer will, at the exclusive option for LetsGrow.com.cn,
                either be settled through arbitration submitted to the China
                International Economic and Trade Arbitration Commission (CIETAC)
                for arbitration in Beijing which shall be conducted in accordance
                with its arbitration rules in effect at the time of applying for
                arbitration, which rules are deemed to be incorporated by
                reference to this clause provided that:<br />i. all proceeding in the
                arbitration shall be conducted in Chinese and more generally, the
                language of the arbitration shall be Chinese;<br />ii. all of the
                arbitrator(s) shall be fluent in Chinese language;<br />iii. the
                arbitrator(s) will apply the Chinese version of this Agreement.
                <br />20.3.
                Any award of the arbitrators shall be enforceable by any court having
                jurisdiction over the Party against which the award has been
                rendered, or wherever assets of the party against which the award
                has been rendered can be located and shall be enforceable.
                </li>
            </ol>
        </div>
      </Layout>
      <Footer path="" />
    </>
  );
};
